import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { Sample } from './sample';
import { MatSliderChange } from '@angular/material/slider';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  portait = false;
  previousSample: Sample;
  samples: Sample[];
  pitchShift = 0;

  readonly soundList = [
    '01-MAX.mp3',
    '02-KNUTSEN.mp3',
    '03-WENDY.mp3',
    '04-HEIKE-STROTZT.mp3',
    '05-VORHANG.mp3',
    '07-VANESSA.mp3',
    '08-TORSTIG.mp3',
    '09-GUNNAR.mp3',
    '10-FLAVIO.mp3',
    '12-OH-FLAVIO.mp3',
    '13-WEISZ-NICHT.mp3',
    '14-WIEBKE.mp3',
    '15-PFERDELUTSCH.mp3',
    '16-MS-DIXIE.mp3',
    '17-PRINCE.mp3',
    '18-JERRY-KIESEMANN.mp3',
    '19-JERRY.mp3',
    '20-KIESEMANN.mp3',
    '21-MARK.mp3',
    '22-MARK-UND-FEXMAN.mp3',
    '23-OH-HERTA.mp3',
    '24-HERR-PFERDELUTSCH.mp3',
    '25-MR-JUICEMAN.mp3',
    '26-AND-DA-BOWMAN.mp3',
  ];


  constructor(private breakpointObserver: BreakpointObserver, private httpClient: HttpClient) {
    breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.portait = result.breakpoints[Breakpoints.HandsetPortrait];
      }
    });
  }

  ngOnInit() {
    this.samples = new Array(this.soundList.length);
    for (let i = 0; i < this.soundList.length; i++) {
      this.samples[i] = new Sample(`/assets/snds/${this.soundList[i]}`, this.soundList[i]);
      this.samples[i].load(this.httpClient);
      this.samples[i].statusChange$.subscribe(() => {
        this.samples = [...this.samples];
      });
    }
    console.log(`Loading ${this.samples.length} samples`);
  }

  onPitchShiftChanged(change: number) {
    this.pitchShift = change;
  }

  onSamplePlayed(sample: Sample) {
    if (this.previousSample && this.previousSample.name != sample.name) {
      this.previousSample.stop();
    }
    sample.play(this.pitchShift);
    this.previousSample = sample;
  }

}
