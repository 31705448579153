import { HttpClient } from '@angular/common/http';
import { createDirective } from '@angular/compiler/src/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Sample } from '../sample';

@Component({
  selector: 'app-pad-trigger',
  templateUrl: './pad-trigger.component.html',
  styleUrls: ['./pad-trigger.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PadTriggerComponent {

  @Input() samples: Sample[];
  @Output() sampleEmitter = new EventEmitter<Sample>();

  onClick(index: number) {
    this.sampleEmitter.emit(this.samples[index]);
  }

}
