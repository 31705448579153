import { HttpClient } from "@angular/common/http";
import { Observable, Subject, timer } from "rxjs";

export class Sample {
  public context: AudioContext;
  public buffer: AudioBuffer;
  public isLoaded: boolean = false;
  public isPlaying: boolean = false;
  
  private statusChangeSubject = new Subject<void>();
  private bufferSource: AudioBufferSourceNode;

  constructor(public url: string, public name: string) {
    this.context = new AudioContext();
  }

  public get statusChange$(): Observable<void> {
    return this.statusChangeSubject;
  }

  load(httpClient: HttpClient) {
    window.AudioContext = window.AudioContext||(window as any).webkitAudioContext;
    httpClient.get(this.url, { responseType: 'arraybuffer'}).pipe().subscribe((audio) => {
      this.isLoaded = true;
      this.context.decodeAudioData(audio, buffer => this.buffer = buffer);
      this.statusChangeSubject.next();
    },
    error => console.error(error));
  }

  play(pitchOffset = 0) {
    this.bufferSource?.stop();
    this.bufferSource?.disconnect();
    delete this.bufferSource;
    this.bufferSource = this.context.createBufferSource();
    this.bufferSource.buffer = this.buffer;
    this.bufferSource.playbackRate.value = 1 + pitchOffset;
    this.bufferSource.connect(this.context.destination);
    this.bufferSource.start()
    this.isPlaying = true;
    this.statusChangeSubject.next();
  }

  stop() {
    this.bufferSource?.stop();
    this.bufferSource?.disconnect();
    delete this.bufferSource;
    this.isPlaying = false; 
    this.statusChangeSubject.next(); 
  }

}