
<ng-container *ngIf="portait; else appContent">
  <div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
    <div fxFlex="1" >
      <h1 class="mat-display-1 label effect-rotate">Rotate device</h1>
    </div>
  </div>
</ng-container>

<ng-template #appContent>
  <div fxLayout="column" fxFill>
    <div fxLayout="row" fxFlex="15" fxFlex.sm="25">
      <div class="area" fxFlex="60">
          <span class="title">WRH-1</span><br/>
          <span class="sub-title">LINEAR WAVEFORM SAMPLER</span>
      </div>
      <div class="area display" fxFlex="40" fxLayoutAlign="center center">
        <span>{{previousSample?.name}}</span><br/>
      </div>
    </div>
    <div fxLayout="row" fxFlex="85" fxFlex.sm="75">
      <div class="area" fxFlex="10" fxLayout="column" fxLayoutAlign="center center">

        <div fxFlex="10">
          <span class="sub-title">PITCH</span>
        </div>
        <div fxFlex="90">
          <mat-slider class="slider" tickInterval="'auto'" (valueChange)="onPitchShiftChanged($event)" vertical="true" width="128px" min="-0.5" max="0.5" step="0.1" value="0"></mat-slider>
        </div>

      </div>
      <div class="area" fxFlex="90">
        <app-pad-trigger [samples]="samples" (sampleEmitter)="onSamplePlayed($event)"></app-pad-trigger>
      </div>
    </div>
  </div>

</ng-template>

